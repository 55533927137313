import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import PaymentPageQuery, {
  type PaymentPageQuery as PaymentPageQueryType,
} from "src/pages/Subscriptions/PaymentPage/__generated__/PaymentPageQuery.graphql"
import PaymentPageLayout from "src/pages/Subscriptions/PaymentPage/PaymentPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/subscribe/payment")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<PaymentPageQueryType>(
      context.relayEnvironment(),
      PaymentPageQuery,
      {},
    )
  },
  pendingComponent: () => <PaymentPageLayout />,
  component: lazyRouteComponent(
    () => import("src/pages/Subscriptions/PaymentPage/PaymentPage"),
  ),
})
