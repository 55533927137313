import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"
import useBudget2024AutumnModulesQuery, {
  useBudget2024AutumnModulesQuery as useBudget2024AutumnModulesQueryType,
} from "src/tools/Budget/Budget2024Autumn/__generated__/useBudget2024AutumnModulesQuery.graphql"

export const Route = createFileRoute("/tools/budget/2025/spring")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) =>
    loadQuery<useBudget2024AutumnModulesQueryType>(
      context.relayEnvironment(),
      useBudget2024AutumnModulesQuery,
      {},
    ),
  component: lazyRouteComponent(
    () => import("src/pages/Tools/Budget2024AutumnPage/Budget2024AutumnPage"),
  ),
})
