import { useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowFlexbox } from "src/glow"
import { usePopup } from "src/hooks/modal"

import { SettingsPageMembership_household$key } from "./__generated__/SettingsPageMembership_household.graphql"
import { CancelFreeTrialModal } from "./components/CancelFreeTrialModal"
import SettingsPageMembershipFeatures from "./components/SettingsPageMembershipFeatures"
import { UnsubscribeModal } from "./components/UnsubscribeModal"

import { SettingsPageMembershipDetails } from "../SettingsPageMembershipDetails"

export function SettingsPageMembership(props: {
  household: SettingsPageMembership_household$key
}) {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembership_household on Household {
        ...SettingsPageMembershipDetails_household
        ...SettingsPageMembershipFeatures_household
      }
    `,
    props.household,
  )

  const cancelFreeTrialPopup = usePopup()
  const unsubscribePopup = usePopup()

  const intl = useIntl()

  const supportEmail = intl.formatMessage({
    id: "page.settings.view.membership.supportEmail",
    defaultMessage: "support@nous.co",
  })

  return (
    <>
      <GlowFlexbox direction="column" gap="4">
        <SettingsPageMembershipDetails
          household={household}
          onCancelFreeTrial={cancelFreeTrialPopup.open}
          onUnsubscribe={unsubscribePopup.open}
        />
        <SettingsPageMembershipFeatures household={household} />
      </GlowFlexbox>

      <CancelFreeTrialModal
        isOpen={cancelFreeTrialPopup.isOpen}
        onClose={cancelFreeTrialPopup.close}
        supportEmail={supportEmail}
      />

      <UnsubscribeModal
        isOpen={unsubscribePopup.isOpen}
        onClose={unsubscribePopup.close}
        supportEmail={supportEmail}
      />
    </>
  )
}
