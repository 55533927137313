import React from "react"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import useElementSize from "src/hooks/useElementSize"
import useToggle from "src/hooks/useToggle"

import { GlowFlexbox, GlowHorizontalDivider, GlowIcon, GlowText } from "."

type GlowAccordionProps = {
  title: string | React.ReactNode
  content: React.ReactNode
  isDefaultOpen?: boolean
  variant?: "primary" | "secondary" | "tertiary"
  withBorder?: boolean
  className?: string
  onClick?: (isOpen: boolean, beenToggled: boolean) => void
}

export function GlowAccordion({
  title,
  content,
  isDefaultOpen = false,
  variant = "primary",
  withBorder = false,
  className,
  onClick,
}: GlowAccordionProps) {
  const id = React.useId()
  const { height, ref: contentRef } = useElementSize<HTMLDivElement>()

  const { value: isOpen, toggle, beenToggled } = useToggle(isDefaultOpen)

  const betterTitle =
    typeof title === "string" ? <GlowText>{title}</GlowText> : title

  return (
    <GlowFlexbox
      direction="column"
      className={clsx(
        "relative w-full",
        variant !== "tertiary" ? "rounded-lg bg-white" : "",
        withBorder && "border",
        className,
      )}
    >
      <GlowFlexbox direction="column">
        {variant !== "secondary" ? (
          <button
            aria-controls={id}
            onClick={() => {
              toggle()
              onClick?.(isOpen, beenToggled)
            }}
            className="group"
          >
            <GlowFlexbox
              as="span"
              alignItems="center"
              padding={variant === "primary" ? "4" : "0"}
            >
              {betterTitle}
              <GlowIcon
                name="arrow_down_1_regular"
                className={clsx(
                  "ml-auto h-4 w-4 transition-all duration-300 ease-in-out",
                  isOpen && "rotate-180 transform",
                )}
              />
            </GlowFlexbox>
          </button>
        ) : (
          <div className="px-4 py-6 md:px-6">{betterTitle}</div>
        )}

        <div
          id={id}
          className={clsx(
            "overflow-hidden transition-all duration-300 ease-in-out",
          )}
          style={{
            height: isOpen ? height : 0,
          }}
          aria-expanded={isOpen}
        >
          <GlowFlexbox
            direction="column"
            className={clsx(
              variant !== "tertiary" ? "px-4 pb-6 md:px-6" : "py-2",
            )}
            innerRef={contentRef}
          >
            {variant === "secondary" && (
              <GlowHorizontalDivider className="mb-4" />
            )}
            {content}
          </GlowFlexbox>
        </div>
      </GlowFlexbox>
      {variant === "secondary" && (
        <button
          aria-controls={id}
          onClick={() => {
            toggle()
            onClick?.(isOpen, beenToggled)
          }}
          className="group"
        >
          <GlowFlexbox
            as="span"
            alignItems="center"
            justifyContent="center"
            className="rounded-b-lg bg-gray-50 px-4 py-3"
          >
            <GlowFlexbox gap="2">
              <GlowIcon
                name={isOpen ? "view_off_regular" : "view_1_regular"}
                className="text-off-black-64 group-hover:text-black"
              />
              <GlowText
                fontWeight="medium"
                className="text-off-black-64 group-hover:text-black group-hover:underline"
              >
                {isOpen ? (
                  <FormattedMessage
                    id="glowAccordion.hide"
                    defaultMessage="Hide details"
                  />
                ) : (
                  <FormattedMessage
                    id="glowAccordion.show"
                    defaultMessage="Show details"
                  />
                )}
              </GlowText>
            </GlowFlexbox>
          </GlowFlexbox>
        </button>
      )}
    </GlowFlexbox>
  )
}
