import { useIntl } from "react-intl"

import MetaTags from "src/components/MetaTags/MetaTags"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowIcon } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function PaymentPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()

  const pageTitle = intl.formatMessage({
    id: "subscriptions.payments.page.title",
    defaultMessage: "Payments",
  })

  const pageDescription = intl.formatMessage({
    id: "subscriptions.payments.page.description",
    defaultMessage: "Subscription Payments page",
  })
  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />

      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              showBack
              title={intl.formatMessage({
                id: "subscriptions.payment.page.mobileNav.title",
                defaultMessage: "Subscribe to Nous",
              })}
              customMenu={
                <GlowIcon name="lock_shield_bold" className="text-gray-500" />
              }
            />
          ),
          bottom: <></>,
        }}
        desktopNav={undefined}
        hideFooter
        className="bg-white"
      >
        {children ?? <LoadingPagePlaceholder />}
      </GlowPageLayout>
    </>
  )
}
