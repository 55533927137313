import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import clockIllustrationSereneBlue from "src/assets/clockIllustrationSereneBlue.png"
import clockIllustrationSereneBlue2x from "src/assets/clockIllustrationSereneBlue@2x.png"
import clockIllustrationSereneBlue3x from "src/assets/clockIllustrationSereneBlue@3x.png"
import { ConnectEmailDisclaimer } from "src/components/ConnectEmailDisclaimer/ConnectEmailDisclaimer"
import { PoweredByAi } from "src/components/PoweredByAi/PoweredByAi"
import { GlowFlexbox, GlowButton, GlowText, GlowCard, GlowLink } from "src/glow"
import OnboardingContent from "src/pages/Onboarding/components/OnboardingContent"
import OnboardingEmilyChatBubble from "src/pages/Onboarding/components/OnboardingEmilyChatBubble"
import { useIsB2B } from "src/utils/useIsB2B"

import { OnboardingConnectEmailBenefitsStepContent_household$key } from "./__generated__/OnboardingConnectEmailBenefitsStepContent_household.graphql"
import { OnboardingConnectEmailBenefitsStepConnectedEmailsSection } from "./OnboardingConnectEmailBenefitsStepConnectedEmailsSection"

type Props = {
  household: OnboardingConnectEmailBenefitsStepContent_household$key | null
  hideBackButton?: boolean
  onConnectEmail?: () => void
  hideSkipButton?: boolean
  onNextStep?: (value: boolean) => void
  noPaddingOrMargin?: boolean
}

export function OnboardingConnectEmailBenefitsStepContent(props: Props) {
  const intl = useIntl()

  const household =
    useFragment(
      graphql`
        fragment OnboardingConnectEmailBenefitsStepContent_household on Household {
          formAnswers {
            connectEmailBenefits: answer(key: "connectEmailBenefits") {
              key @required(action: THROW)
              id @required(action: THROW)
              booleanValue
            }
          }
          emailConnections {
            ...OnboardingConnectEmailBenefitsStepConnectedEmailsSection_emailConnections
          }
          ...useIsB2B_household
        }
      `,
      props.household,
    ) ?? null

  const isB2B = useIsB2B({ household })

  return (
    <OnboardingContent noHeader noPaddingOrMargin={props.noPaddingOrMargin}>
      <GlowFlexbox direction="column" alignItems="center" gap="6">
        <GlowFlexbox direction="column" alignItems="center" gap="4">
          <img
            srcSet={`${clockIllustrationSereneBlue}, ${clockIllustrationSereneBlue2x} 2x, ${clockIllustrationSereneBlue3x} 3x`}
            alt=""
            className="w-24"
          />
          <GlowText fontWeight="medium" textAlign="center">
            <FormattedMessage
              id="onboarding.connectEmailBenefits.header"
              defaultMessage="Save time with Nous AI - connect email inboxes and we'll automatically find bills and contracts sent by your providers."
            />
          </GlowText>
        </GlowFlexbox>

        {household && household.emailConnections.length === 0 ? (
          <GlowCard colour="transparent" className="w-full">
            <GlowFlexbox direction="column" alignItems="center" gap="4">
              <GlowText textAlign="center">
                <FormattedMessage
                  id="onboarding.connectEmailBenefits.benefits.helpText"
                  defaultMessage="Connect a personal inbox to get started"
                />
              </GlowText>
              <GlowButton
                onClick={() =>
                  props.onConnectEmail?.() ?? props.onNextStep?.(true)
                }
                variant="primary"
                label={intl.formatMessage({
                  id: "onboarding.connectEmailBenefits.cta.connect",
                  defaultMessage: "Connect an inbox",
                })}
                iconName="add_circle_bold"
                className="w-full"
              />
            </GlowFlexbox>
          </GlowCard>
        ) : (
          <OnboardingConnectEmailBenefitsStepConnectedEmailsSection
            emailConnections={household?.emailConnections ?? null}
            onConnectEmail={() =>
              props.onConnectEmail?.() ?? props.onNextStep?.(true)
            }
          />
        )}

        <OnboardingEmilyChatBubble>
          <GlowText isDisplay>
            {isB2B ? (
              <FormattedMessage
                id="onboarding.connectEmailBenefits.emilyBubble.b2b"
                defaultMessage="Don't connect your work inbox. Connect a personal inbox that contains bills or emails from your
          providers."
              />
            ) : (
              <FormattedMessage
                id="onboarding.connectEmailBenefits.emilyBubble.d2c"
                defaultMessage="Make sure you connect an inbox that contains bills or emails from your
          providers."
              />
            )}
          </GlowText>
        </OnboardingEmilyChatBubble>

        {!props.hideSkipButton && (
          <GlowLink
            onClick={() => props.onNextStep?.(false)}
            label={intl.formatMessage({
              id: "onboarding.connectEmailBenefits.cta.skip",
              defaultMessage: "Skip for now",
            })}
          />
        )}

        <ConnectEmailDisclaimer />

        <PoweredByAi />
      </GlowFlexbox>
    </OnboardingContent>
  )
}
