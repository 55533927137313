import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import PropositionPageQuery, {
  type PropositionPageQuery as PropositionPageQueryType,
} from "src/pages/Subscriptions/PropositionPage/__generated__/PropositionPageQuery.graphql"
import PropositionPageLayout from "src/pages/Subscriptions/PropositionPage/components/PropositionPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/subscribe/")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<PropositionPageQueryType>(
      context.relayEnvironment(),
      PropositionPageQuery,
      {},
    )
  },
  pendingComponent: () => <PropositionPageLayout />,
  component: lazyRouteComponent(
    () => import("src/pages/Subscriptions/PropositionPage/PropositionPage"),
  ),
})
