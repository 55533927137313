import { GlowFlexbox } from "./GlowFlexbox"
import { GlowInput, GlowInputErrors } from "./GlowInput"
import { GlowQuizInput } from "./GlowQuizInput"
import { GlowText } from "./GlowText"

export type GlowDayPickerDate = {
  day: string
  month: string
  year: string
}

export type GlowMonthPickerDate = {
  month: string
  year: string
}

type CommonProps = {
  errors?: string[]
  className?: string
  legend: string
  assistiveText?: string
  hideLegend?: boolean
  disabled?: boolean
}

type DayProps = {
  onChange: (date: GlowDayPickerDate) => void
  value: GlowDayPickerDate
  dayPlaceholder?: string
  monthPlaceholder?: string
  yearPlaceholder?: string
  dayLabel: string
  monthLabel: string
  yearLabel: string
  dayAutocomplete?: string
  monthAutocomplete?: string
  yearAutocomplete?: string
}

type MonthProps = {
  onChange: (date: GlowMonthPickerDate) => void
  value: GlowMonthPickerDate
  monthPlaceholder?: string
  yearPlaceholder?: string
  monthLabel: string
  yearLabel: string
  monthAutocomplete?: string
  yearAutocomplete?: string
}

export type GlowDayPickerProps = CommonProps & DayProps

export type GlowMonthPickerProps = CommonProps & MonthProps

export function GlowDayPicker(props: GlowDayPickerProps) {
  return <InnerGlowDatePicker {...props} granularity="day" />
}
export function GlowQuizDayPicker(props: GlowDayPickerProps) {
  return <InnerGlowDatePicker {...props} granularity="day" quiz />
}

export function GlowMonthPicker(props: GlowMonthPickerProps) {
  return <InnerGlowDatePicker {...props} granularity="month" />
}

export function GlowQuizMonthPicker(props: GlowMonthPickerProps) {
  return <InnerGlowDatePicker {...props} granularity="month" quiz />
}

function InnerGlowDatePicker(
  props: { quiz?: boolean } & (
    | ({ granularity: "day" } & GlowDayPickerProps)
    | ({ granularity: "month" } & GlowMonthPickerProps)
  ),
) {
  const C = props.quiz ? GlowQuizInput : GlowInput

  return (
    <GlowFlexbox as="fieldset" direction="column" gap="1">
      {props.hideLegend ? null : (
        <GlowText as="legend" fontWeight="medium" size="sm">
          {props.legend}
        </GlowText>
      )}
      {props.assistiveText && (
        <GlowText className="text-off-black-64" size="xs">
          {props.assistiveText}
        </GlowText>
      )}
      <GlowFlexbox gap="4">
        {props.granularity != "month" && (
          <GlowFlexbox grow={2} basis="25%">
            <C
              disabled={props.disabled}
              type="number"
              placeholder={props.dayPlaceholder}
              label={props.dayLabel}
              value={props.value.day}
              autoComplete={props.dayAutocomplete}
              onChange={(day) => {
                props.onChange({
                  day,
                  month: props.value.month,
                  year: props.value.year,
                })
              }}
              min={1}
              max={31}
            />
          </GlowFlexbox>
        )}
        <GlowFlexbox grow={2} basis="25%">
          <C
            disabled={props.disabled}
            type="number"
            placeholder={props.monthPlaceholder}
            label={props.monthLabel}
            value={props.value.month}
            min={1}
            max={12}
            autoComplete={props.monthAutocomplete}
            onChange={(month) => {
              if (props.granularity == "month") {
                props.onChange({
                  month,
                  year: props.value.year,
                })
              } else {
                props.onChange({
                  day: props.value.day,
                  month,
                  year: props.value.year,
                })
              }
            }}
          />
        </GlowFlexbox>
        <GlowFlexbox grow={4} basis="50%">
          <C
            disabled={props.disabled}
            type="number"
            placeholder={props.yearPlaceholder}
            label={props.yearLabel}
            value={props.value.year}
            min={1900}
            autoComplete={props.yearAutocomplete}
            onChange={(year) => {
              if (props.granularity == "month") {
                props.onChange({
                  year,
                  month: props.value.month,
                })
              } else {
                props.onChange({
                  day: props.value.day,
                  year,
                  month: props.value.month,
                })
              }
            }}
          />
        </GlowFlexbox>
      </GlowFlexbox>
      <GlowInputErrors errors={props.errors} />
    </GlowFlexbox>
  )
}
