import { useIntl } from "react-intl"

import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowIcon, GlowLayoutCentered } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function ConfirmationPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()

  const pageTitle = intl.formatMessage({
    id: "subscriptions.confirmation.page.title",
    defaultMessage: "Subscription Confirmation",
  })

  const pageDescription = intl.formatMessage({
    id: "subscriptions.confirmation.page.description",
    defaultMessage: "Subscription Confirmation page",
  })
  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />

      <div className="radial-gradient h-full">
        <GlowPageLayout
          mobileNav={{
            top: (
              <MobileTopNav
                variant="secondary"
                showBack
                title={intl.formatMessage({
                  id: "subscriptions.confirmation.page.mobileNav.title",
                  defaultMessage: "You’ve subscribed",
                })}
                customMenu={
                  <GlowIcon name="lock_shield_bold" className="text-gray-500" />
                }
              />
            ),
            bottom: <></>,
          }}
          desktopNav={<DesktopNav showSecondaryLinks={false} />}
          hideFooter
        >
          <GlowLayoutCentered variant="narrow" className="mx-4 mb-4 pt-12">
            {children ?? <LoadingPagePlaceholder />}
          </GlowLayoutCentered>
        </GlowPageLayout>
      </div>
    </>
  )
}
