/**
 * @generated SignedSource<<9a8bf194355b0995fc45fefe1dd02a6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmationPageQuery$variables = Record<PropertyKey, never>;
export type ConfirmationPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"ConfirmationPageSubscriptionDetails_household" | "useRedirectToWaitlist_household">;
  };
};
export type ConfirmationPageQuery = {
  response: ConfirmationPageQuery$data;
  variables: ConfirmationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountInGbpPounds",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRedirectToWaitlist_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfirmationPageSubscriptionDetails_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfirmationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inWaitlist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDelegated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdSavingsForecast",
            "kind": "LinkedField",
            "name": "savingsForecast",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RewardsForecast",
                "kind": "LinkedField",
                "name": "rewardsForecast",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfMonthsSubscriptionCoveredByRewards",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Money",
                    "kind": "LinkedField",
                    "name": "expectedFutureRewards",
                    "plural": false,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdSubscriptionDetails",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fbfcd4eb753dfc5f824ecef23a89e80",
    "id": null,
    "metadata": {},
    "name": "ConfirmationPageQuery",
    "operationKind": "query",
    "text": "query ConfirmationPageQuery {\n  household {\n    ...useRedirectToWaitlist_household\n    ...ConfirmationPageSubscriptionDetails_household\n    id\n  }\n}\n\nfragment ConfirmationPageSubscriptionDetails_household on Household {\n  savingsForecast {\n    rewardsForecast {\n      ...SubscriptionCoveredByRewardsBanner_rewardsForecast\n    }\n  }\n  subscription {\n    price {\n      amountInGbpPounds\n    }\n  }\n}\n\nfragment SubscriptionCoveredByRewardsBanner_rewardsForecast on RewardsForecast {\n  numberOfMonthsSubscriptionCoveredByRewards\n  expectedFutureRewards {\n    amountInGbpPounds\n  }\n}\n\nfragment useRedirectToWaitlist_household on Household {\n  inWaitlist\n  serviceLevel\n  isDelegated\n}\n"
  }
};
})();

(node as any).hash = "75a6f93790608260b425ac7bb879bd41";

export default node;
