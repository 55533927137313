import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/widgets/budget/2024/autumn")({
  beforeLoad: ({ search }) => {
    throw redirect({
      to: "/widgets/budget/2025/spring",
      replace: true,
      search,
    })
  },
})
