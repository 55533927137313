import { FormattedMessage, useIntl } from "react-intl"

import {
  GlowFlexbox,
  GlowHorizontalDivider,
  GlowIcon,
  GlowLink,
  GlowText,
} from "src/glow"
import { GlowAccordion } from "src/glow/GlowAccordion"

import rewardsIcon from "./assets/rewardsIcon.png"
import rewardsIcon2x from "./assets/rewardsIcon@2x.png"
import rewardsIcon3x from "./assets/rewardsIcon@3x.png"
import rewardsShinyIcon from "./assets/rewardsShinyIcon.png"
import rewardsShinyIcon2x from "./assets/rewardsShinyIcon@2x.png"
import rewardsShinyIcon3x from "./assets/rewardsShinyIcon@3x.png"

export default function InfluencerBanner() {
  return (
    <GlowAccordion
      title={<AccordionTitle />}
      content={<AccordionContent />}
      variant="tertiary"
      className="bg-virtuous-green-400 mb-6 cursor-pointer px-4 py-3 md:mb-0 md:mt-6 [@media(min-width:528px)]:rounded-lg"
    />
  )
}

const AccordionTitle = () => {
  return (
    <GlowFlexbox
      alignItems="center"
      justifyContent="space-between"
      className="w-full"
    >
      <img
        className="h-5 w-[35px]"
        srcSet={`${rewardsIcon}, ${rewardsIcon2x} 2x, ${rewardsIcon3x} 3x`}
        alt=""
      />
      <GlowText isDisplay size="sm">
        <FormattedMessage
          id="onboarding.influencerAccordion.title"
          defaultMessage="Get a <b>£50</b> cash rewards bonus"
          values={{
            b: (chunks) => (
              <GlowText isDisplay fontWeight="bold">
                {chunks}
              </GlowText>
            ),
          }}
        />
      </GlowText>
      <div className="h-1 w-1 md:h-4 md:w-4" />
    </GlowFlexbox>
  )
}

const AccordionContent = () => {
  const intl = useIntl()

  const linkUrl = intl.formatMessage({
    id: "onboarding.influencerAccordion.link.url",
    defaultMessage:
      " https://nousforyourhouse.notion.site/Terms-and-conditions-136e4fd0813680be8772d36895b819f1?pvs=74",
  })

  return (
    <GlowFlexbox direction="column" gap="3">
      <GlowHorizontalDivider />

      <GlowFlexbox
        className="w-full"
        alignItems="start"
        justifyContent="space-between"
      >
        <GlowFlexbox
          direction="column"
          className="w-1/3 max-w-[150px]"
          gap="2"
          alignItems="center"
          justifyContent="center"
        >
          <GlowIcon name="touch_finger_3_bold" className="h-5 w-5" />
          <GlowText size="xs" textAlign="center">
            <FormattedMessage
              id="onboarding.influencerAccordion.content.1"
              defaultMessage="Sign up to use Nous today"
            />
          </GlowText>
        </GlowFlexbox>

        <GlowFlexbox
          direction="column"
          className="w-1/3 max-w-[150px]"
          gap="2"
          alignItems="center"
          justifyContent="center"
        >
          <GlowIcon name="currency_pound_bubble_bold" className="h-5 w-5" />
          <GlowText size="xs" textAlign="center">
            <FormattedMessage
              id="onboarding.influencerAccordion.content.2"
              defaultMessage="We'll find you some savings"
            />
          </GlowText>
        </GlowFlexbox>

        <GlowFlexbox
          direction="column"
          className="w-1/3 max-w-[150px]"
          gap="2"
          alignItems="center"
          justifyContent="center"
        >
          <img
            className="h-5 w-[30px]"
            srcSet={`${rewardsShinyIcon}, ${rewardsShinyIcon2x} 2x, ${rewardsShinyIcon3x} 3x`}
            alt=""
          />
          <GlowText size="xs" textAlign="center">
            <FormattedMessage
              id="onboarding.influencerAccordion.content.3"
              defaultMessage="You'll get £50 cash rewards"
            />
          </GlowText>
        </GlowFlexbox>
      </GlowFlexbox>

      <GlowLink
        href={linkUrl}
        label={intl.formatMessage({
          id: "onboarding.influencerAccordion.link",
          defaultMessage: "Learn more",
        })}
        target="_blank"
        rel="noreferrer"
        size="sm"
      />
    </GlowFlexbox>
  )
}
