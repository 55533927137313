import { useIntl } from "react-intl"

import MetaTags from "src/components/MetaTags/MetaTags"
import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import { useViewport, Viewport } from "src/hooks/responsive"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

export default function PropositionPageLayout({
  children,
}: {
  children?: React.ReactNode
}) {
  const intl = useIntl()
  const isMobile = useViewport() < Viewport.MD

  const pageTitle = intl.formatMessage({
    id: "proposition.page.title",
    defaultMessage: "Subscribe",
  })

  const pageDescription = intl.formatMessage({
    id: "proposition.page.description",
    defaultMessage: "Subscribe proposition page",
  })
  return (
    <>
      <MetaTags title={pageTitle} description={pageDescription} />

      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              showBack
              title={intl.formatMessage({
                id: "proposition.page.mobileNav.title",
                defaultMessage: "Save more, worry less.",
              })}
            />
          ),
          bottom: <></>,
        }}
        desktopNav={<DesktopNav showSecondaryLinks={false} />}
        hideFooter
        className="md:radial-gradient bg-white"
      >
        <GlowLayoutCentered variant={isMobile ? "ultra-wide" : "narrow"}>
          {children ?? <LoadingPagePlaceholder />}
        </GlowLayoutCentered>
      </GlowPageLayout>
    </>
  )
}
