import { useState } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { BankAccount } from "src/components/BankAccount/BankAccount"
import { useCheckGate } from "src/Gate"
import { GlowTooltip } from "src/glow"
import { GlowButton } from "src/glow/GlowButton"
import { GlowCard } from "src/glow/GlowCard"
import { GlowCheckbox } from "src/glow/GlowCheckbox"
import { GlowFlexbox } from "src/glow/GlowFlexbox"
import { GlowIcon } from "src/glow/GlowIcon"
import { GlowLink } from "src/glow/GlowLink"
import { GlowText } from "src/glow/GlowText"
import { usePopup } from "src/hooks/modal"
import OnboardingEmilyChatBubble from "src/pages/Onboarding/components/OnboardingEmilyChatBubble"
import useTracking from "src/tracking/useTracking"

import { BankAccountDetailsCard_bankAccount$key } from "./__generated__/BankAccountDetailsCard_bankAccount.graphql"
import { BankAccountDetailsCard_household$key } from "./__generated__/BankAccountDetailsCard_household.graphql"
import { BankAccountAddOrEditPopup } from "./BankAccountAddOrEditPopup"

export const BankAccountDetailsCard = (props: {
  household: BankAccountDetailsCard_household$key
  onBack?: () => void
  onSkipped?: () => void
  onContinue?: () => void
  onBankDetailsSubmitted?: () => void
  hideCashRewardsCheckbox?: boolean
  allowAddMultiple?: boolean
}) => {
  const household = useFragment(
    graphql`
      fragment BankAccountDetailsCard_household on Household {
        bankAccounts {
          id
          ...BankAccountDetailsCard_bankAccount
          ...BankAccountAddOrEditPopup_bankAccount
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  const track = useTracking()

  const checkGate = useCheckGate()

  const newBankAccountPopup = usePopup()

  const showSkip =
    checkGate("skip_service_level_bank_details_steps") &&
    (props.onSkipped || props.onContinue)

  const [sendCash, setSendCash] = useState(true)
  const [_addNewBankAccount, setAddNewBankAccount] = useState(false)

  const hasBankAccounts =
    household.bankAccounts && household.bankAccounts.length > 0

  return (
    <>
      <GlowFlexbox
        direction="column"
        gap="6"
        margin={{
          sm: "4",
          md: {
            x: "4",
            top: "6",
            bottom: "4",
          },
        }}
      >
        <GlowCard>
          <GlowFlexbox direction="column" gap="6">
            <GlowText size="base" textAlign="center" fontWeight="medium">
              <FormattedMessage
                id="onboarding.page.bankDetails.accountDetailsAddition.header.improved"
                defaultMessage="Add bank account details to your wallet so we can set up new providers for you and send you cash rewards."
              />
            </GlowText>

            {hasBankAccounts ? (
              household.bankAccounts.map((bankAccount) => (
                <EditableBankAccountOrEmpty
                  key={bankAccount.id}
                  bankAccount={bankAccount ?? null}
                  onBankDetailsSubmitted={props.onBankDetailsSubmitted}
                />
              ))
            ) : (
              <EditableBankAccountOrEmpty
                bankAccount={null}
                onBankDetailsSubmitted={props.onBankDetailsSubmitted}
              />
            )}

            {hasBankAccounts && props.allowAddMultiple ? (
              <GlowButton
                variant="secondary"
                size="sm"
                onClick={() => {
                  setAddNewBankAccount(true)
                  newBankAccountPopup.open()
                }}
                iconName="add_regular"
                label={intl.formatMessage({
                  id: "onboarding.page.bankDetails.accountDetailsAddition.addAnotherButton",
                  defaultMessage: "Add another bank account",
                })}
              />
            ) : null}

            {props.hideCashRewardsCheckbox ? null : (
              <GlowFlexbox gap="1">
                <GlowCheckbox
                  isChecked={sendCash}
                  isDisabled={!household.bankAccounts}
                  label={intl.formatMessage({
                    id: "onboarding.page.bankDetails.accountDetailsAddition.sendCash",
                    defaultMessage:
                      "Send any cash rewards I earn to this account",
                  })}
                  labelFontSize="sm"
                  labelClassName="font-medium"
                  onChange={(checked) => {
                    if (checked && household.bankAccounts) {
                      track([
                        "BankAccount",
                        "CashRewardsTurnedOn",
                        { bankAccountId: "" },
                      ])
                    } else if (household.bankAccounts) {
                      track([
                        "BankAccount",
                        "CashRewardsTurnedOff",
                        { bankAccountId: "" },
                      ])
                    }

                    setSendCash(checked)
                  }}
                />

                <GlowTooltip
                  handle={
                    <GlowIcon
                      name="information_circle_bold"
                      className="h-5 w-5 text-gray-300"
                    />
                  }
                >
                  <GlowText textAlign="center">
                    <FormattedMessage
                      id="onboarding.page.bankDetails.accountDetailsAddition.sendCash.tooltip"
                      defaultMessage="Sometimes providers pay us a commission for switching our members to new deals. We pass these to our members in the form of cash rewards. <a>Learn more.</a>"
                      values={{
                        br: <br />,
                        a: (chunk) => (
                          <GlowLink
                            size="xs"
                            className="!inline"
                            label={chunk}
                            href={intl.formatMessage({
                              id: "onboarding.page.bankDetails.accountDetailsAddition.sendCash.tooltip.url",
                              defaultMessage: "https://www.nous.co/faqs",
                            })}
                          />
                        ),
                      }}
                    />
                  </GlowText>
                </GlowTooltip>
              </GlowFlexbox>
            )}

            {hasBankAccounts && props.onContinue && (
              <GlowButton
                variant="primary"
                label={intl.formatMessage({
                  id: "onboarding.page.bankDetails.accountDetailsAddition.nextStepButton",
                  defaultMessage: "Continue",
                })}
                onClick={() => {
                  track(["BankAccount", "DetailsEntryConfirmed"])
                  props.onContinue?.()
                }}
              />
            )}

            <AccordionWarning />

            {showSkip && (
              <GlowLink
                label={intl.formatMessage({
                  id: "onboarding.page.bankDetails.accountDetailsAddition.skipLink",
                  defaultMessage: "I'll do this later",
                })}
                onClick={() => {
                  track(["BankAccount", "DetailsEntrySkipped"])
                  props.onSkipped?.() ?? props.onContinue?.()
                }}
              />
            )}
          </GlowFlexbox>
        </GlowCard>
      </GlowFlexbox>

      <BankAccountAddOrEditPopup
        bankAccount={null}
        isOpen={newBankAccountPopup.isOpen}
        onClose={newBankAccountPopup.close}
        onAdded={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Add" }])
          newBankAccountPopup.close()
          props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
        }}
        onEdited={() => {
          track(["BankAccount", "DetailsEntrySaved", { mode: "Edit" }])
          newBankAccountPopup.close()
          props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
        }}
      />
    </>
  )
}

const EditableBankAccountOrEmpty = (props: {
  bankAccount: BankAccountDetailsCard_bankAccount$key | null
  onBankDetailsSubmitted?: () => void
}) => {
  const intl = useIntl()
  const track = useTracking()

  const bankAccountPopup = usePopup()

  const bankAccount = useFragment(
    graphql`
      fragment BankAccountDetailsCard_bankAccount on BankAccount {
        id
        ...BankAccountAddOrEditPopup_bankAccount
        ...BankAccount
      }
    `,
    props.bankAccount,
  )

  return (
    <GlowCard colour="transparent">
      {!bankAccount && (
        <>
          <GlowFlexbox
            direction="column"
            gap="3"
            alignItems="center"
            className="relative"
          >
            <GlowIcon name="money_wallet_bold" />
            <GlowText size="sm" textAlign="center">
              <FormattedMessage
                id="onboarding.page.bankDetails.accountDetailsAddition.addText"
                defaultMessage="Add a bank account to your wallet"
              />
            </GlowText>
            <GlowButton
              variant="primary"
              size="sm"
              label={intl.formatMessage({
                id: "onboarding.page.bankDetails.accountDetailsAddition.addButton",
                defaultMessage: "Add account details",
              })}
              iconName="add_circle_bold"
              iconPosition="left"
              className="w-full"
              onClick={() => {
                track(["BankAccount", "DetailsEntryStarted", { mode: "Add" }])
                bankAccountPopup.open()
              }}
            />
            <GlowIcon
              name="lock_shield_bold"
              className="absolute -right-2 -top-2 h-3 w-3 text-gray-300"
            />
          </GlowFlexbox>
          <BankAccountAddOrEditPopup
            bankAccount={null}
            isOpen={bankAccountPopup.isOpen}
            onClose={bankAccountPopup.close}
            onAdded={() => {
              track(["BankAccount", "DetailsEntrySaved", { mode: "Add" }])
              bankAccountPopup.close()
              props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
            }}
            onEdited={() => {
              track(["BankAccount", "DetailsEntrySaved", { mode: "Edit" }])
              bankAccountPopup.close()
              props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
            }}
          />
        </>
      )}
      {bankAccount && (
        <>
          <BankAccount
            key={bankAccount.id}
            bankAccount={bankAccount}
            onEdit={() => {
              track(["BankAccount", "DetailsEntryStarted", { mode: "Edit" }])
              bankAccountPopup.open()
            }}
          />
          <BankAccountAddOrEditPopup
            bankAccount={bankAccount}
            isOpen={bankAccountPopup.isOpen}
            onClose={bankAccountPopup.close}
            onAdded={() => {
              track(["BankAccount", "DetailsEntrySaved", { mode: "Add" }])
              bankAccountPopup.close()
              props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
            }}
            onEdited={() => {
              track(["BankAccount", "DetailsEntrySaved", { mode: "Edit" }])
              bankAccountPopup.close()
              props.onBankDetailsSubmitted && props.onBankDetailsSubmitted()
            }}
          />
        </>
      )}
    </GlowCard>
  )
}

const AccordionWarning = () => {
  return (
    <OnboardingEmilyChatBubble>
      <GlowText isDisplay>
        <FormattedMessage
          id="onboarding.page.bankDetails.accountDetailsAddition.emilyBubble"
          defaultMessage="We'll <b>never</b> charge you without your explicit approval, so there's no risk in trying Nous."
          values={{
            b: (chunk) => <b>{chunk}</b>,
          }}
        />
      </GlowText>
    </OnboardingEmilyChatBubble>
  )
}
