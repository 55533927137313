import { FormattedMessage, useIntl } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { LogoIcon } from "src/components/PremiumBadge/PremiumBadge"
import { useCheckGate } from "src/Gate"
import {
  GlowButton,
  GlowCard,
  GlowFlexbox,
  GlowIcon,
  GlowLink,
  GlowProgressBar,
  GlowText,
  GlowTooltip,
} from "src/glow"
import { useIsPremium } from "src/hooks/useIsPremium"
import { titleMap } from "src/pages/SettingsPage/utils"

import { SettingsPageMembershipDetails_household$key } from "./__generated__/SettingsPageMembershipDetails_household.graphql"
import { SettingsPageMembershipDetailsButtonSection_household$key } from "./__generated__/SettingsPageMembershipDetailsButtonSection_household.graphql"
import { SettingsPageMembershipDetailsPlanSection_household$key } from "./__generated__/SettingsPageMembershipDetailsPlanSection_household.graphql"
import { SettingsPageMembershipDetailsPlanSectionFreeTrial_household$key } from "./__generated__/SettingsPageMembershipDetailsPlanSectionFreeTrial_household.graphql"
import { SettingsPageMembershipDetailsPlanSectionPlan_household$key } from "./__generated__/SettingsPageMembershipDetailsPlanSectionPlan_household.graphql"
import { SettingsPageMembershipDetailsTitleSection_household$key } from "./__generated__/SettingsPageMembershipDetailsTitleSection_household.graphql"

export const SettingsPageMembershipDetails = (props: {
  household: SettingsPageMembershipDetails_household$key
  onGoToMembershipView?: () => void
  onCancelFreeTrial?: () => void
  onUnsubscribe?: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetails_household on Household {
        ...SettingsPageMembershipDetailsTitleSection_household
        ...SettingsPageMembershipDetailsPlanSection_household
        ...SettingsPageMembershipDetailsButtonSection_household
      }
    `,
    props.household,
  )

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6" alignItems="start">
        <SettingsPageMembershipDetailsTitleSection household={household} />

        <SettingsPageMembershipDetailsPlanSection household={household} />

        <SettingsPageMembershipDetailsButtonSection
          household={household}
          onGoToMembershipView={props.onGoToMembershipView}
          onUpgradeMembership={() => {
            alert("This functionality is not available at the moment")
          }}
          onKeepMembership={() => {
            alert("This functionality is not available at the moment")
          }}
          onCancelFreeTrial={props.onCancelFreeTrial}
          onUnsubscribe={props.onUnsubscribe}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}

const SettingsPageMembershipDetailsTitleSection = (props: {
  household: SettingsPageMembershipDetailsTitleSection_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetailsTitleSection_household on Household {
        currentMember {
          joinedHousehold
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  return (
    <GlowFlexbox direction="column" gap="1" alignItems="start">
      <GlowText size="base" fontWeight="bold" textAlign="left">
        <FormattedMessage {...titleMap.membership} />
      </GlowText>

      {household.currentMember?.joinedHousehold && (
        <GlowText size="sm" textAlign="left">
          <FormattedMessage
            id="page.settings.membershipDetails.memberJoinedHouseholdDate"
            defaultMessage="Member since {joinedHousehold}"
            values={{
              joinedHousehold: intl.formatDate(
                household.currentMember.joinedHousehold,
                {
                  month: "long",
                  year: "numeric",
                },
              ),
            }}
          />
        </GlowText>
      )}
    </GlowFlexbox>
  )
}

const SettingsPageMembershipDetailsPlanSection = (props: {
  household: SettingsPageMembershipDetailsPlanSection_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetailsPlanSection_household on Household {
        ...SettingsPageMembershipDetailsPlanSectionPlan_household
        ...SettingsPageMembershipDetailsPlanSectionFreeTrial_household
      }
    `,
    props.household,
  )

  return (
    <GlowFlexbox direction="column" gap="4" className="w-full">
      <SettingsPageMembershipDetailsPlanSectionPlan household={household} />
      <SettingsPageMembershipDetailsPlanSectionFreeTrial
        household={household}
      />
    </GlowFlexbox>
  )
}

const SettingsPageMembershipDetailsPlanSectionPlan = (props: {
  household: SettingsPageMembershipDetailsPlanSectionPlan_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetailsPlanSectionPlan_household on Household {
        ...useIsPremium_household
        subscription {
          isOnFreeTrial
        }
        organization {
          name
        }
      }
    `,
    props.household,
  )

  const isPremium = useIsPremium(household)
  const isB2B = hasOrganization(household)

  return (
    <GlowFlexbox
      direction="row"
      gap="3"
      alignItems="center"
      justifyContent="start"
    >
      <LogoIcon
        size="md"
        variant={isPremium ? "premium" : "free"}
        withBackground
      />

      <GlowText size="xl" isDisplay>
        {household.subscription?.isOnFreeTrial ? (
          <FormattedMessage
            id="page.settings.membershipDetails.membershipPlan.premium.onFreeTrial"
            defaultMessage="Nous Premium (free trial)"
          />
        ) : isPremium ? (
          <FormattedMessage
            id="page.settings.membershipDetails.membershipPlan.premium"
            defaultMessage="Nous Premium"
          />
        ) : (
          <FormattedMessage
            id="page.settings.membershipDetails.membershipPlan.free"
            defaultMessage="Nous Free"
          />
        )}
      </GlowText>

      {isPremium && (
        <GlowTooltip
          handle={
            <GlowIcon
              name="information_circle_bold"
              className="h-4 w-4 text-gray-300"
            />
          }
        >
          <GlowText textAlign="center">
            {isB2B ? (
              <FormattedMessage
                id="page.settings.membershipDetails.membershipPlan.tooltip.b2b"
                defaultMessage="Your employer, {employerName}, has gifted you Nous Premium."
                values={{
                  employerName: household.organization.name,
                }}
              />
            ) : (
              <FormattedMessage
                id="page.settings.membershipDetails.membershipPlan.tooltip.d2c"
                defaultMessage="We've given you Nous Premium to try, on us."
              />
            )}
          </GlowText>
        </GlowTooltip>
      )}
    </GlowFlexbox>
  )
}

const hasOrganization = (household: {
  organization?: { name?: string | null } | null
}): household is {
  organization: { name: string }
} => Boolean(household.organization?.name)

const SettingsPageMembershipDetailsPlanSectionFreeTrial = (props: {
  household: SettingsPageMembershipDetailsPlanSectionFreeTrial_household$key
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetailsPlanSectionFreeTrial_household on Household {
        subscription {
          isOnFreeTrial
          freeTrialCompletionPercentage
          freeTrialEndDate
          freeTrialDaysLeft
          freeTrialCountdownMonths
        }
      }
    `,
    props.household,
  )

  const intl = useIntl()

  if (
    household.subscription?.isOnFreeTrial &&
    typeof household.subscription.freeTrialCompletionPercentage === "number"
  ) {
    return (
      <GlowFlexbox direction="column" gap="2" className="w-full">
        <GlowProgressBar
          decimalPercentageComplete={
            household.subscription.freeTrialCompletionPercentage
          }
          variant="nous-glow"
          className="w-full"
        />
        {typeof household.subscription.freeTrialDaysLeft === "number" &&
          household.subscription.freeTrialEndDate && (
            <GlowText size="sm" textAlign="left">
              <FormattedMessage
                id="page.settings.membershipDetails.membershipPlan.freeTrialProgress"
                defaultMessage="{daysLeft, plural, one {# day} other {# days}} left of free trial - your free trial ends on {endDate}"
                values={{
                  daysLeft: household.subscription.freeTrialDaysLeft,
                  endDate: intl.formatDate(
                    household.subscription.freeTrialEndDate,
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    },
                  ),
                }}
              />
            </GlowText>
          )}
      </GlowFlexbox>
    )
  }

  if (
    household.subscription?.isOnFreeTrial &&
    typeof household.subscription.freeTrialCountdownMonths === "number" &&
    household.subscription.freeTrialCompletionPercentage === null
  ) {
    return (
      <GlowText size="sm" textAlign="left">
        <FormattedMessage
          id="page.settings.membershipDetails.membershipPlan.freeTrialCountdownStart"
          defaultMessage="Your free trial lasts for {freeTrialCountdownMonths} months after your first completed switch. "
          values={{
            freeTrialCountdownMonths:
              household.subscription.freeTrialCountdownMonths,
          }}
        />
      </GlowText>
    )
  }

  return null
}

const SettingsPageMembershipDetailsButtonSection = (props: {
  household: SettingsPageMembershipDetailsButtonSection_household$key
  onGoToMembershipView?: () => void
  onUpgradeMembership: () => void
  onKeepMembership: () => void
  onCancelFreeTrial?: () => void
  onUnsubscribe?: () => void
}) => {
  const checkGate = useCheckGate()
  const membershipSettingsUpgradePathEnabled = checkGate(
    "membership_settings_upgrade_path",
  )

  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipDetailsButtonSection_household on Household {
        ...useIsPremium_household
        subscription {
          isOnFreeTrial
        }
      }
    `,
    props.household,
  )

  const isPremium = useIsPremium(household)

  const intl = useIntl()

  return (
    <GlowFlexbox
      direction="column"
      className="w-full"
      gap="4"
      alignItems="center"
    >
      {membershipSettingsUpgradePathEnabled &&
        isPremium &&
        household.subscription?.isOnFreeTrial && (
          <GlowButton
            variant="primary"
            size="sm"
            label={intl.formatMessage({
              id: "page.settings.membershipDetails.keepMembership",
              defaultMessage: "Stay on Premium",
            })}
            className="w-full"
            onClick={props.onKeepMembership}
          />
        )}

      {membershipSettingsUpgradePathEnabled && !isPremium && (
        <GlowButton
          variant="primary"
          size="sm"
          label={intl.formatMessage({
            id: "page.settings.membershipDetails.upgradeMembership",
            defaultMessage: "Upgrade now",
          })}
          className="w-full"
          onClick={props.onUpgradeMembership}
        />
      )}

      {props.onGoToMembershipView && (
        <GlowButton
          variant="tertiary"
          size="sm"
          label={intl.formatMessage({
            id: "page.settings.membershipDetails.goToMembershipView",
            defaultMessage: "Manage membership",
          })}
          className="w-full"
          onClick={props.onGoToMembershipView}
        />
      )}

      {isPremium &&
        household.subscription?.isOnFreeTrial &&
        props.onCancelFreeTrial && (
          <GlowLink
            size="sm"
            label={intl.formatMessage({
              id: "page.settings.membershipDetails.cancelFreeTrial",
              defaultMessage: "Cancel free trial",
            })}
            onClick={props.onCancelFreeTrial}
          />
        )}

      {isPremium &&
        !household.subscription?.isOnFreeTrial &&
        props.onUnsubscribe && (
          <GlowLink
            size="sm"
            label={intl.formatMessage({
              id: "page.settings.membershipDetails.unsubscribe",
              defaultMessage: "Unsubscribe",
            })}
            onClick={props.onUnsubscribe}
          />
        )}
    </GlowFlexbox>
  )
}
