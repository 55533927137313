import { useIntl, FormattedMessage } from "react-intl"

import { GlowFlexbox, GlowText, GlowLink } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"

export function CancelFreeTrialModal(props: {
  isOpen: boolean
  onClose: () => void
  supportEmail: string
}) {
  const intl = useIntl()

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage({
        id: "page.settings.view.membership.cancelFreeTrial.modal.title",
        defaultMessage: "Cancel Free Trial",
      })}
    >
      <GlowFlexbox direction="column" gap="4">
        <GlowText size="base">
          <FormattedMessage
            id="page.settings.view.membership.cancelFreeTrial.modal.paragraph.1"
            defaultMessage="Please contact us via WhatsApp or at {supportEmail} if you have concerns about free trial."
            values={{
              supportEmail: (
                <GlowLink
                  href={`mailto:${props.supportEmail}`}
                  label={props.supportEmail}
                  size="base"
                  className="!inline-block"
                />
              ),
            }}
          />
        </GlowText>
        <GlowText size="base">
          <FormattedMessage
            id="page.settings.view.membership.cancelFreeTrial.modal.paragraph.2"
            defaultMessage="Remember: You won't be charged at the end of the free trial. We'll automatically move you to Nous Free if you decide not to stay on Premium."
          />
        </GlowText>
      </GlowFlexbox>
    </GlowModal>
  )
}
