import { createFileRoute, lazyRouteComponent } from "@tanstack/react-router"
import { loadQuery } from "react-relay"

import ConfirmationPageQuery, {
  type ConfirmationPageQuery as ConfirmationPageQueryType,
} from "src/pages/Subscriptions/ConfirmationPage/__generated__/ConfirmationPageQuery.graphql"
import ConfirmationPageLayout from "src/pages/Subscriptions/ConfirmationPage/ConfirmationPageLayout"
import requireIsLoggedIn from "src/routeUtils/requireIsLoggedIn"

export const Route = createFileRoute("/subscribe/confirmed")({
  beforeLoad: requireIsLoggedIn({ redirectTo: "/login" }),
  loader: ({ context }) => {
    return loadQuery<ConfirmationPageQueryType>(
      context.relayEnvironment(),
      ConfirmationPageQuery,
      {},
    )
  },
  pendingComponent: () => <ConfirmationPageLayout />,
  component: lazyRouteComponent(
    () => import("src/pages/Subscriptions/ConfirmationPage/ConfirmationPage"),
  ),
})
