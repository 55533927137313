import { useIntl, FormattedMessage, defineMessage } from "react-intl"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { GlowCard, GlowFlexbox, GlowText, GlowIcon, GlowLink } from "src/glow"
import useIsPremium from "src/hooks/useIsPremium"

import { SettingsPageMembershipFeatures_household$key } from "./__generated__/SettingsPageMembershipFeatures_household.graphql"

export default function SettingsPageMembershipFeatures(props: {
  household: SettingsPageMembershipFeatures_household$key
}) {
  const intl = useIntl()

  const household = useFragment(
    graphql`
      fragment SettingsPageMembershipFeatures_household on Household {
        ...useIsPremium_household
      }
    `,
    props.household,
  )

  const isPremium = useIsPremium(household)

  if (!isPremium) {
    return null
  }

  return (
    <GlowCard>
      <GlowFlexbox direction="column" gap="6" alignItems="start">
        <GlowText size="base" fontWeight="bold">
          <FormattedMessage
            id="page.settings.view.membership.features.title"
            defaultMessage="You've got access to these Premium features:"
          />
        </GlowText>
        <GlowFlexbox direction="column" gap="2">
          {features.map((feature, index) => {
            return (
              <GlowFlexbox
                key={`feature-${index}`}
                direction="row"
                gap="2"
                alignItems="center"
                justifyContent="start"
              >
                <GlowIcon name="check_circle_1_bold" className="h-5 w-5" />
                <GlowText size="base">
                  <FormattedMessage {...feature} />
                </GlowText>
              </GlowFlexbox>
            )
          })}
        </GlowFlexbox>
        <GlowLink
          size="sm"
          href="http://nous.co/pricing"
          target="_blank"
          rel="noreferrer"
          label={intl.formatMessage({
            id: "page.settings.view.membership.features.learnMore",
            defaultMessage: "Learn more about Nous Premium",
          })}
        />
      </GlowFlexbox>
    </GlowCard>
  )
}

export const features = [
  defineMessage({
    id: "page.settings.view.membership.features.1",
    defaultMessage: "Cash rewards",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.2",
    defaultMessage: "Priority live chat support",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.3",
    defaultMessage: "Management for all your household services",
  }),
  defineMessage({
    id: "page.settings.view.membership.features.4",
    defaultMessage: "Choice of savings mode",
  }),
]
