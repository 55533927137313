import { Fragment } from "react"

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { useViewport, Viewport } from "src/hooks/responsive"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowIcon } from "./GlowIcon"
import { GlowText } from "./GlowText"

type CommonProps = {
  children: React.ReactNode
  colour?: "white" | "glow"
  isOpen: boolean
  modalClassName?: string
  noPadding?: boolean
  title?: string
  level?: "0" | "1" | "2"
  hideDrawerTitle?: boolean
  disableManualClose?: boolean
}

type GlowModalProps = CommonProps &
  (
    | {
        hideManualClose: true
        onClose?: () => void
      }
    | {
        hideManualClose?: false
        onClose: () => void
      }
  )

const backgroundColorMap = {
  white: "bg-white",
  glow: "radial-gradient",
}

export const GlowModal = ({
  children,
  colour = "white",
  hideManualClose,
  hideDrawerTitle,
  isOpen,
  level = "0",
  modalClassName,
  noPadding,
  onClose,
  title,
  disableManualClose,
}: GlowModalProps) => {
  const viewport = useViewport()

  const content = (
    <GlowFlexbox
      direction="column"
      gap="0"
      className={clsx(
        "relative",
        "h-full max-h-[90vh] min-w-0 overflow-y-auto",
        "md:h-auto md:min-w-[400px] md:max-w-[528px]",
        "rounded-t-2xl shadow-xl md:rounded-lg",
        level === "1" ? "md:z-modal-level-1" : "md:z-modal",
        backgroundColorMap[colour],
        modalClassName,
      )}
    >
      {(title || !hideManualClose) && (
        <GlowFlexbox
          justifyContent="center"
          alignItems="center"
          className="md:border-gray-500/12 w-full px-4 md:border-b md:px-6"
        >
          {title && (
            <div
              className={clsx(
                "px-6 py-4 text-center",
                !hideManualClose && "ml-auto",
                hideDrawerTitle && "hidden md:block",
              )}
            >
              <GlowText
                size={{ sm: "lg", md: "base" }}
                className="font-medium md:font-bold"
              >
                {title}
              </GlowText>
            </div>
          )}
          {!hideManualClose && (
            <button
              type="button"
              className="text-off-black-64 ml-auto cursor-pointer py-3 disabled:cursor-not-allowed"
              onClick={onClose}
              disabled={disableManualClose}
            >
              <span className="sr-only">
                <FormattedMessage
                  id="glowModal.closeButton.screenReader.text"
                  defaultMessage="close panel"
                />
              </span>
              <GlowIcon name="close_regular" className="h-5 w-5" />
            </button>
          )}
        </GlowFlexbox>
      )}

      <div className={clsx(noPadding || "px-4 pb-4 md:p-6")}>{children}</div>
    </GlowFlexbox>
  )

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx(
          "z-drawer relative",
          "md:fixed md:inset-0 md:flex md:flex-wrap md:overflow-y-auto",
          level === "0" && "md:z-modal",
          level === "1" && "md:z-modal-level-1",
          level === "2" && "md:z-modal-level-2",
        )}
        onClose={() => {
          if (hideManualClose || disableManualClose) {
            return
          }

          onClose()
        }}
      >
        {viewport < Viewport.MD ? (
          <>
            <TransitionChild
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="bg-off-black-64 fixed inset-0 cursor-default transition-opacity" />
            </TransitionChild>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 min-h-full w-full overflow-hidden">
                <div className="pointer-events-none fixed bottom-0 right-0 flex w-full">
                  <TransitionChild
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 md:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 md:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <DialogPanel className="pointer-events-auto relative w-full">
                      {content}
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </div>
          </>
        ) : (
          <GlowFlexbox
            direction="column"
            alignItems="center"
            justifyContent="center"
            className="h-full w-full"
          >
            <div className="opacity-64 fixed inset-0 bg-gray-500" />

            <DialogPanel>{content}</DialogPanel>
          </GlowFlexbox>
        )}
      </Dialog>
    </Transition>
  )
}
