import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/tools/budget/2024/autumn")({
  beforeLoad: ({ search }) => {
    throw redirect({
      to: "/tools/budget/2025/spring",
      replace: true,
      search,
    })
  },
})
