import { FormattedMessage, useIntl } from "react-intl"
import { graphql, useFragment, useMutation } from "react-relay"

import { useAuthActions } from "src/auth"
import { notify } from "src/components/ToastNotification/ToastNotification"
import { GlowButton, GlowFlexbox, GlowLink, GlowText } from "src/glow"
import { GlowModal } from "src/glow/GlowModal"
import { useModal } from "src/hooks/modal"
import { logger } from "src/logger"
import useTracking from "src/tracking/useTracking_LEGACY"

import { DeleteMyAccount_user$key } from "./__generated__/DeleteMyAccount_user.graphql"

export const DeleteMyAccount = (props: {
  user?: DeleteMyAccount_user$key | null
}) => {
  const intl = useIntl()
  const track = useTracking()
  const { logOut } = useAuthActions()
  const user = useFragment(
    graphql`
      fragment DeleteMyAccount_user on User {
        canDeleteAccount
      }
    `,
    props.user,
  )

  const modal = useModal()

  const [deleteAccount, deleting] = useMutation(graphql`
    mutation DeleteMyAccountMutation {
      deleteMyAccount
    }
  `)

  const supportEmail = intl.formatMessage({
    id: "deleteMyAccount.modal.content.cannotDeleteAccount.supportEmail",
    defaultMessage: "support@nous.co",
  })

  return (
    <>
      <GlowLink
        onClick={() => {
          track("Clicked delete my account")
          modal.open()
        }}
        className="text-gray-500/64"
        label={intl.formatMessage({
          id: "deleteMyAccount.cta",
          defaultMessage: "Delete account",
        })}
      />

      <GlowModal
        isOpen={modal.isOpen}
        onClose={modal.close}
        title={intl.formatMessage({
          id: "deleteMyAccount.modal.title",
          defaultMessage: "Delete account",
        })}
      >
        {user?.canDeleteAccount ? (
          <GlowFlexbox direction="column" gap="6">
            <GlowText as="p" textAlign="center">
              <FormattedMessage
                id="deleteMyAccount.modal.content.canDeleteAccount"
                defaultMessage="Unless you are the only member, deleting your account will not delete the household. All members of a household must delete their account in order to delete a household.{br}{br}This action cannot be undone."
                values={{
                  br: <br />,
                }}
              />
            </GlowText>

            <GlowFlexbox
              direction={{ sm: "column-reverse", md: "row" }}
              gap="2"
            >
              <GlowButton
                variant="secondary"
                onClick={modal.close}
                margin={{
                  sm: { top: "2" },
                  md: { top: "0" },
                }}
                className="w-full"
                label={intl.formatMessage({
                  id: "deleteMyAccount.cancel",
                  defaultMessage: "Cancel",
                })}
              />
              <GlowButton
                variant="primary-red"
                onClick={() =>
                  deleteAccount({
                    variables: {},
                    onCompleted(_, errors) {
                      if (errors) {
                        notify.error("There was an issue deleting your account")
                        return
                      }
                      logOut().then(() => {
                        window.location.href = window.location.origin
                      })
                    },
                    onError: (error) => {
                      logger.error("Error deleting account", error)
                    },
                  })
                }
                isLoading={deleting}
                className="w-full"
                label={intl.formatMessage({
                  id: "deleteMyAccount.confirm",
                  defaultMessage: "Confirm, delete account",
                })}
              />
            </GlowFlexbox>
          </GlowFlexbox>
        ) : (
          <GlowText as="p" textAlign="center">
            <FormattedMessage
              id="deleteMyAccount.modal.content.cannotDeleteAccount"
              defaultMessage="Please contact us at {supportEmail} or via WhatsApp if you would like to delete your account."
              values={{
                supportEmail: (
                  <GlowLink
                    href={`mailto:${supportEmail}`}
                    label={supportEmail}
                    size="base"
                    className="!inline-block"
                  />
                ),
              }}
            />
          </GlowText>
        )}
      </GlowModal>
    </>
  )
}
