import {
  ReactNode,
  useState,
  useContext,
  createContext,
  Children,
  useMemo,
  useEffect,
  useRef,
} from "react"

import clsx from "clsx"

import { GlowFlexbox } from "./GlowFlexbox"
import { GlowResponsiveSpacing, glowSpacingToClassNames } from "./structure"

type GlowTabsContextType = {
  activeTabIndex: number
  setActiveTabIndex: (index: number) => void
  isTabActive: (index: number) => boolean
}

const GlowTabsContext = createContext<GlowTabsContextType | undefined>(
  undefined,
)

function useGlowTabsContext() {
  const context = useContext(GlowTabsContext)
  if (!context) {
    throw new Error("Tab components must be used within a GlowTabs component")
  }
  return context
}

type GlowTabsProps = {
  children: ReactNode
  defaultIndex?: number
  className?: string
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
  activeIndex?: number
  onChange?: (index: number) => void
}

export function GlowTabs({
  children,
  defaultIndex = 0,
  className,
  margin,
  padding,
  activeIndex,
  onChange,
}: GlowTabsProps) {
  const [internalActiveIndex, setInternalActiveIndex] = useState(defaultIndex)

  const isControlled = activeIndex !== undefined

  const activeTabIndex = isControlled ? activeIndex : internalActiveIndex

  const setActiveTabIndex = (index: number) => {
    if (!isControlled) {
      setInternalActiveIndex(index)
    }
    onChange?.(index)
  }

  const isTabActive = (index: number) => index === activeTabIndex

  const contextValue: GlowTabsContextType = {
    activeTabIndex,
    setActiveTabIndex,
    isTabActive,
  }

  return (
    <GlowTabsContext.Provider value={contextValue}>
      <div
        className={clsx(
          "w-full",
          ...glowSpacingToClassNames(padding ?? {}, "p"),
          ...glowSpacingToClassNames(margin ?? {}, "m"),
          className,
        )}
      >
        {children}
      </div>
    </GlowTabsContext.Provider>
  )
}

type GlowTabListProps = {
  children: ReactNode
  className?: string
}

export function GlowTabList({ children, className }: GlowTabListProps) {
  const { activeTabIndex } = useGlowTabsContext()

  const tabCount = useMemo(() => Children.count(children), [children])

  const indicatorStyle = useMemo(() => {
    return {
      left: `calc(${activeTabIndex * 100}% / ${tabCount})`,
      width: `calc(100% / ${tabCount})`,
    }
  }, [activeTabIndex, tabCount])

  return (
    <div className={clsx("relative w-full", className)}>
      <GlowFlexbox className="w-full">{children}</GlowFlexbox>
      <div
        className="absolute bottom-0 h-0.5 bg-gray-500 transition-all duration-300 ease-in-out will-change-transform"
        style={indicatorStyle}
      />
      <div className="bg-off-black-12 absolute bottom-0 h-[1px] w-full" />
    </div>
  )
}

type GlowTabProps = {
  children: ReactNode
  index: number
  disabled?: boolean
  className?: string
  activeClassName?: string
  disabledClassName?: string
  onClick?: () => void
}

export function GlowTab({
  children,
  index,
  disabled = false,
  className,
  activeClassName,
  disabledClassName,
  onClick,
}: GlowTabProps) {
  const { setActiveTabIndex, isTabActive } = useGlowTabsContext()

  const isActive = isTabActive(index)

  const handleClick = () => {
    if (!disabled) {
      setActiveTabIndex(index)
      onClick?.()
    }
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={handleClick}
      className={clsx(
        "text-off-black-48 w-full py-3 transition-colors duration-150 focus:outline-none",
        "hover:text-gray-500",
        isActive ? "!text-gray-500" : "text-off-black-48",
        isActive && activeClassName,
        disabled && "cursor-not-allowed opacity-50",
        disabled && disabledClassName,
        className,
      )}
      aria-selected={isActive}
      role="tab"
    >
      {children}
    </button>
  )
}

type GlowTabPanelProps = {
  children: ReactNode
  index: number
  className?: string
  activeClassName?: string
  onRender?: () => void
}

export function GlowTabPanel({
  children,
  index,
  className,
  activeClassName,
  onRender,
}: GlowTabPanelProps) {
  const { isTabActive } = useGlowTabsContext()
  const isActive = isTabActive(index)
  const hasRendered = useRef(false)

  useEffect(() => {
    if (isActive && !hasRendered.current) {
      onRender?.()
      hasRendered.current = true
    } else if (!isActive) {
      hasRendered.current = false
    }
  }, [isActive, onRender])

  if (!isActive) {
    return null
  }

  return (
    <div
      className={clsx(
        isActive && activeClassName,
        !isActive && "hidden",
        className,
      )}
      role="tabpanel"
      hidden={!isActive}
    >
      {children}
    </div>
  )
}
