/**
 * @generated SignedSource<<8fe44a26227427f7706833307d9b91f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentPageQuery$variables = Record<PropertyKey, never>;
export type PaymentPageQuery$data = {
  readonly household: {
    readonly " $fragmentSpreads": FragmentRefs<"SubscriptionForm_household" | "SubscriptionOverview_household" | "useRedirectToWaitlist_household">;
  };
};
export type PaymentPageQuery = {
  response: PaymentPageQuery$data;
  variables: PaymentPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountInGbpPounds",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubscriptionOverview_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubscriptionForm_household"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useRedirectToWaitlist_household"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PaymentPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Household",
        "kind": "LinkedField",
        "name": "household",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdSubscriptionDetails",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Money",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdSavingsForecast",
            "kind": "LinkedField",
            "name": "savingsForecast",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RewardsForecast",
                "kind": "LinkedField",
                "name": "rewardsForecast",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfMonthsSubscriptionCoveredByRewards",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Money",
                    "kind": "LinkedField",
                    "name": "expectedFutureRewards",
                    "plural": false,
                    "selections": (v0/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccounts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HouseholdAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstLine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondLine",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "town",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "county",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postcode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inWaitlist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDelegated",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33713169c0a814454b3586ca8dd3e879",
    "id": null,
    "metadata": {},
    "name": "PaymentPageQuery",
    "operationKind": "query",
    "text": "query PaymentPageQuery {\n  household {\n    ...SubscriptionOverview_household\n    ...SubscriptionForm_household\n    ...useRedirectToWaitlist_household\n    id\n  }\n}\n\nfragment BankAccountRadioButton_bankAccount on BankAccount {\n  id\n  firstName\n  lastName\n  accountNumber\n  sortCode\n}\n\nfragment SubscriptionCoveredByRewardsBanner_rewardsForecast on RewardsForecast {\n  numberOfMonthsSubscriptionCoveredByRewards\n  expectedFutureRewards {\n    amountInGbpPounds\n  }\n}\n\nfragment SubscriptionDetails_subscription on HouseholdSubscriptionDetails {\n  price {\n    amountInGbpPounds\n  }\n}\n\nfragment SubscriptionForm_household on Household {\n  bankAccounts {\n    id\n    title\n    firstName\n    lastName\n    accountNumber\n    sortCode\n    ...BankAccountRadioButton_bankAccount\n  }\n  address {\n    firstLine\n    secondLine\n    town\n    county\n    postcode\n  }\n}\n\nfragment SubscriptionOverview_household on Household {\n  subscription {\n    ...SubscriptionDetails_subscription\n  }\n  savingsForecast {\n    rewardsForecast {\n      ...SubscriptionCoveredByRewardsBanner_rewardsForecast\n    }\n  }\n}\n\nfragment useRedirectToWaitlist_household on Household {\n  inWaitlist\n  serviceLevel\n  isDelegated\n}\n"
  }
};
})();

(node as any).hash = "4838289a2f915d418dc8843e21d52e16";

export default node;
