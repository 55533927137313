import { FormattedMessage, useIntl } from "react-intl"

import { GlowButton, GlowText } from "src/glow"
import { GlowFlexbox } from "src/glow/GlowFlexbox"
import { GlowLink } from "src/glow/GlowLink"
import { GlowModal } from "src/glow/GlowModal"

export function UnsubscribeModal(props: {
  isOpen: boolean
  onClose: () => void
  supportEmail: string
}) {
  const intl = useIntl()

  return (
    <GlowModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={intl.formatMessage({
        id: "page.settings.view.membership.unsubscribe.modal.title",
        defaultMessage: "Please confirm your request",
      })}
    >
      <GlowFlexbox direction="column" gap="4">
        <GlowText size="base">
          <FormattedMessage
            id="page.settings.view.membership.unsubscribe.modal.paragraph"
            defaultMessage="So that we can confirm your request to unsubscribe, please contact us via Whatsapp or by emailing {supportEmail}"
            values={{
              supportEmail: (
                <GlowLink
                  href={`mailto:${props.supportEmail}`}
                  label={props.supportEmail}
                  size="base"
                  className="!inline-block"
                />
              ),
            }}
          />
        </GlowText>
        <GlowButton
          label={intl.formatMessage({
            id: "page.settings.view.membership.unsubscribe.modal.button",
            defaultMessage: "Got it",
          })}
          onClick={props.onClose}
        />
      </GlowFlexbox>
    </GlowModal>
  )
}
