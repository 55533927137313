import React, { useEffect } from "react"

import * as Sentry from "@sentry/react"
import { ErrorBoundary } from "react-error-boundary"
import { HelmetProvider } from "react-helmet-async"

import { initDatadogUserMonitoring } from "src/datadogUserMonitoring"
import { ViewportProvider } from "src/hooks/responsive"
import { addUserIdGlobalContextToLogger, initLogger, logger } from "src/logger"

import Router from "./AppRouter"
import { AuthProvider, useUser } from "./auth"
import AdminToolbar from "./components/AdminToolbar/AdminToolbar"
import { ToastNotifications } from "./components/ToastNotification/ToastNotification"
import { GateProvider } from "./Gate"
import { HubspotProvider } from "./hooks/hubspot"
import useEffectOnce from "./hooks/useEffectOnce"
import { EmulateUserProvider } from "./hooks/useEmulateUser"
import useIdentify from "./hooks/useIdentify"
import useRefreshIn from "./hooks/useRefreshIn"
import { Intl, IntlDebugContextSafeHaven } from "./intl"
import LoadingPagePlaceholder from "./LoadingPagePlaceholder"
import FatalErrorPage from "./pages/FatalErrorPage/FatalErrorPage"
import { RelayProvider } from "./relay/client"

import { ignoreErrors } from "../../api/src/lib/sentry/ignoreErrors"

if (__SENTRY_DSN_FRONTEND__) {
  Sentry.init({
    ignoreErrors,
    dsn: __SENTRY_DSN_FRONTEND__,
    environment: __NOUS_ENV__,
    release: __GITHUB_SHA__ ?? "local",
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 0.0 as we only want to use Sentry for
    // error monitoring (not for performance monitoring)
    tracesSampleRate: 0.0,
  })
}

if (__DATADOG_SITE__) {
  if (__DATADOG_CLIENT_TOKEN__) {
    initLogger(__DATADOG_CLIENT_TOKEN__, __DATADOG_SITE__)
  }

  if (__DATADOG_RUM_CLIENT_TOKEN__ && __DATADOG_RUM_APPLICATION_ID__) {
    initDatadogUserMonitoring(
      __DATADOG_RUM_CLIENT_TOKEN__,
      __DATADOG_RUM_APPLICATION_ID__,
      __DATADOG_SITE__,
    )
  }
}

function SetUserLoggingContext({ children }: { children: React.ReactNode }) {
  const user = useUser()
  useEffectOnce(
    () => {
      if (user) {
        Sentry.setUser(user)
        addUserIdGlobalContextToLogger(user.id)
      }
    },
    [user],
    user !== null,
  )
  return <>{children}</>
}

function IdentifyUserWrapper(props: { children: React.ReactNode }) {
  const user = useUser()
  const identify = useIdentify()
  useEffect(() => {
    if (user) {
      const { id, ...traits } = user
      identify({
        id,
        traits,
      })
    }
  }, [user, identify])
  return <>{props.children}</>
}

const App = () => {
  useRefreshIn({ days: 1 })
  return (
    <EmulateUserProvider>
      <Intl>
        <ErrorBoundary
          FallbackComponent={FatalErrorPage}
          onError={logger.errorWithSentryExeption}
        >
          <AuthProvider>
            <React.Suspense fallback={<LoadingPagePlaceholder />}>
              <RelayProvider>
                <GateProvider>
                  <HelmetProvider>
                    <SetUserLoggingContext>
                      <ViewportProvider>
                        <HubspotProvider>
                          <IdentifyUserWrapper>
                            <Router />
                            <IntlDebugContextSafeHaven>
                              <AdminToolbar />
                            </IntlDebugContextSafeHaven>
                            <ToastNotifications />
                          </IdentifyUserWrapper>
                        </HubspotProvider>
                      </ViewportProvider>
                    </SetUserLoggingContext>
                  </HelmetProvider>
                </GateProvider>
              </RelayProvider>
            </React.Suspense>
          </AuthProvider>
        </ErrorBoundary>
      </Intl>
    </EmulateUserProvider>
  )
}

export default App
